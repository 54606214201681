import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer, EuiButton } from '@elastic/eui';
import PowerlineCards from 'components/Quick_Installation/Powerline/PowerlineCards';
import YoutubeVideo from 'components/Quick_Installation/Powerline/YoutubeVideoCardINLAN';
import PrimaryBox from 'components/Quick_Installation/Powerline/PrimaryBox';
import DangerBox from 'components/Quick_Installation/Powerline/DangerBox';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/Powerline/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-LAN Powerline",
  "path": "/Quick_Installation/Powerline/",
  "dateChanged": "2017-09-26",
  "author": "Mike Polinowski",
  "excerpt": "A Network Connection over your Power Grid",
  "image": "./QI_SearchThumb_Powerline.png",
  "social": "/images/Search/QI_SearchThumb_Powerline.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-LAN_white.webp",
  "chapter": "Installation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">







    <SEOHelmet title='IN-LAN Powerline' dateChanged='2017-09-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='A Network Connection over your Power Grid' image='/images/Search/QI_SearchThumb_Powerline.png' twitter='/images/Search/QI_SearchThumb_Powerline.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/Powerline/' locationFR='/fr/Quick_Installation/Powerline/' crumbLabel="Powerline" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "powerline",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#powerline",
        "aria-label": "powerline permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Powerline`}</h1>
    <Link to="/Products/IN-LAN/" mdxType="Link"><EuiButton fill style={{
        float: 'right'
      }} mdxType="EuiButton">Installation</EuiButton></Link>
    <h2 {...{
      "id": "network-over-your-power-grid",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#network-over-your-power-grid",
        "aria-label": "network over your power grid permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Network over your Power Grid`}</h2>
    <YoutubeVideo mdxType="YoutubeVideo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><strong parentName="p">{`IN-LAN`}</strong>{` is an intelligent and secure technology that lets you set up a home network easily via your household power grid - without the need of complex and expensive dedicated cabling. IN-LAN communication now attains speeds you would expect from other LAN technologies. IN-LAN uses the household power grid to transfer data between computers equipped with suitable adapters and other network components. As a result, any power outlet can be used as a network access point. State-of-the-art technology ensures that the power and data networks do not interfere with one another.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4b3ebecf2c85a255b7af5ef6e08f073f/8c557/IN-LAN500-Installation.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAAsTAAALEwEAmpwYAAABvElEQVQY0yXPvW7aUAAFYL9A+g5Vqw6kapd0qNSo3aOqv1v6OEjNQkUHw2B3bRwiTGonDsglkVBpLYKv/zC+9uX62rpGYAKGmQFVmG880pHOYbrdruu6tm07OQCAYRiu65qmCQDQdR0A4DiO67qOM7Bt27Is0zTtHGNZFiEEYxzmIITD4ZDjOE3TKKUQQkKILMuCIMRxjDFOkiRNp1EUYYwZAEAYhqMcxngwGMxmsyzL5vM5xtjzPELIYrFYrVa7giiKxWJR13VCCGMYRhAEvu8HQYAQsm07TdPNZkMpRQjt8vV6vVwux+NxvV4/fPVy/8nDt0dHCCHGcZxOp+N5XpIklFJCCIQQoaDf7++2+L6PUGAYxmg0UlX1xcFB4fGj4+MvlNLt51qt1my1bm5uLy+vNO0f9H3lSu71emEYBlvob/ePoiiUJr+ki8M3r/f2Hnz6+CGKom1ZVdunDYk/rf08r7euW/f3M0mS7+760+k0juPJZOJ53plwni2yUrn87vP7QmH/2dPnuq4zoihKknQmCN9Kpe/lMsuyHMdVKlVFuW63279zqqqybIXn+Wq1+vXkhOd/NBoXzWbzPwmYalPhd/a4AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4b3ebecf2c85a255b7af5ef6e08f073f/e4706/IN-LAN500-Installation.avif 230w", "/en/static/4b3ebecf2c85a255b7af5ef6e08f073f/d1af7/IN-LAN500-Installation.avif 460w", "/en/static/4b3ebecf2c85a255b7af5ef6e08f073f/70e80/IN-LAN500-Installation.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4b3ebecf2c85a255b7af5ef6e08f073f/a0b58/IN-LAN500-Installation.webp 230w", "/en/static/4b3ebecf2c85a255b7af5ef6e08f073f/bc10c/IN-LAN500-Installation.webp 460w", "/en/static/4b3ebecf2c85a255b7af5ef6e08f073f/426ac/IN-LAN500-Installation.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4b3ebecf2c85a255b7af5ef6e08f073f/81c8e/IN-LAN500-Installation.png 230w", "/en/static/4b3ebecf2c85a255b7af5ef6e08f073f/08a84/IN-LAN500-Installation.png 460w", "/en/static/4b3ebecf2c85a255b7af5ef6e08f073f/8c557/IN-LAN500-Installation.png 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4b3ebecf2c85a255b7af5ef6e08f073f/8c557/IN-LAN500-Installation.png",
              "alt": "IN-LAN 500 Powerline Adapter",
              "title": "IN-LAN 500 Powerline Adapter",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <h4 {...{
      "id": "what-is-the-difference-between-in-lan-500--in-lan-500p",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#what-is-the-difference-between-in-lan-500--in-lan-500p",
        "aria-label": "what is the difference between in lan 500  in lan 500p permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What is the difference between IN-LAN 500 & IN-LAN 500p?`}</h4>
    <p>{`The `}<strong parentName="p">{`P`}</strong>{` in IN-LAN 500p stands for pass-through. Unlike the base model the 500p will block your power outlet but pass through the existing one. Both models offer the same advantages otherwise:`}</p>
    <ul>
      <li parentName="ul">{`Use existing power lines to implement a network with IN-LAN.`}</li>
      <li parentName="ul">{`Very simple plug&play technology. Just plug into the wall socket and you're done.`}</li>
      <li parentName="ul">{`Ultra-fast data transfer up to 500Mbps.`}</li>
      <li parentName="ul">{`Expand your network with for e.g. IP cameras without laying network cables.`}</li>
      <li parentName="ul">{`A very detailed instruction will make the installation very easy.`}</li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <PowerlineCards mdxType="PowerlineCards" />
    <EuiSpacer mdxType="EuiSpacer" />
    <DangerBox mdxType="DangerBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      